<template>
  <div class="">
     <!-- <button
            v-if="getNavbarData.userType == 'shopify'"
            class="btn px-3 py-2  shopify-btn"
            @click="openShopifyProductModal()">
            import from <i class="fab fa-shopify"></i>
          </button> -->
   

   

    <!-- Shopify Modal -->
    <ModalVue
      ref="Modal"
      id="copyShopifyProducts"
      title="Import From Shopify"
      size="lg">
      <div class="my-2" :class="!isLoadingSync ? 'd-none' : 'd-block'">
        <ProgressbarVue ref="progressbar"></ProgressbarVue>
        <span
          class="text-success text-center"
          style="font-size: 12px; text-align: center">
          Products syncing soon, continue tasks uninterrupted.
        </span>
      </div>

      <div class="row mx-3">
        <div class="col-12 p-0">
          <div class="d-flex align-items-center h-100">
            <FilterBox
              style="flex:1"
              :search="true"
              placeholder="Search Products..."
              @input="searchProductQueryHandle($event)"></FilterBox>
          </div>
        </div>
       
          
   
        <div class="col-12 p-0">
          <div class="d-flex flex-wrap pt-1" style="gap:10px">
               
            <FilterSelect ref="status" :isDynamic="false"  filterFor="Status" @update="(e)=>filter.status=e.map(status=>status.title)" :static-options='[{title:"ACTIVE"}, {title: "DRAFT"}, {title: "ARCHIVED"}]' ></FilterSelect>
            <FilterSelect ref="collections" :isDynamic="false"  type="radio"  filterFor="Collections" @update="(e)=>filter.collection=e.id" :static-options="filterObject.collections" ></FilterSelect>
            <FilterSelect ref="tags" :isDynamic="false" type="radio"  filterFor="Tags" @update="(e)=>filter.tag=e.title" :static-options="filterObject.tags" ></FilterSelect>
            <FilterSelect ref="vendors" :isDynamic="false" type="radio" filterFor="Vendors" @update="(e)=>filter.vendor=e.title" :static-options="filterObject.vendor" ></FilterSelect>
             <b-button variant="link"  style="font-size: 14px; color: #4d1b7e;"  v-if="isHideSelectAllButton"  @click="clearAll()">Clear All</b-button>
            </div>
        </div>
      </div>
      <div class="loader px-2">
        <div
          class="d-flex justify-content-between py-2"
          >
        
<!--<div class="d-flex" style="gap:20px">
     <b-form-checkbox
                name="withVariant"
               small
              v-model="withVariant"
                class="checkbox d-flex align-items-center cursor-pointer">
               With variants
                </b-form-checkbox
              >
 
    
              </div>-->
                <div
            class="d-flex align-items-center "
            v-if="isLoadingShopifyProducts">
            <b-spinner small style="color: #4d1b7e"></b-spinner>
            <span class="ml-2">Loading Products</span>
          </div>
        
        </div>
      </div>
      <b-skeleton-table
        v-if="isLoadingShopifyProductFirstTime"
        :rows="5"
        :columns="3"
        :table-props="{ bordered: true, striped: true }"></b-skeleton-table>
      <div class="data-table" v-else-if="shopifyProducts.length">
        <div
          class=""
          style="max-height:48vh; min-height: 48vh; overflow: auto">
          <b-table
          :sticky-header="true"
          :no-border-collapse="true"
          @row-clicked="RowClick"
            head-class="text-center"
            class="leadTable"
            :stickyColumn="true"
            hover
            :fields="shopifyProductsFields"
            :items="shopifyProducts">
            <template #head(checkbox)>
              <b-form-checkbox
                size="lg"
                :disabled="
                  isLoadingShopifyProducts ||
                  isLoadingSelectedShopifyProducts ||
                  isLoadingSync
                "
                v-model="shopifySelectAllCheck"
                @change="shopifyProductSelectAllToggle($event)"
                class="checkbox selectAll"></b-form-checkbox>
            </template>
            <template #cell(checkbox)="data">
              <b-form-checkbox
                :value="data.item"
                v-model="shopifySelectedProducts"
                :disabled="
                  isLoadingShopifyProducts ||
                  isLoadingSelectedShopifyProducts ||
                  isLoadingSync
                "
                size="lg"
                class="checkbox"></b-form-checkbox>
            </template>
            <template #cell(image)="data">
              <div class="d-flex gap align-items-center">
                <lazy-image
                    class="circle-image mr-2"
                    :src="
                       data.item.images.length>0
                        ? data.item.images[0].src
                        : 'https://images.quizell.com/website/default_start_page.png'
                    "
                    :key="data.index+data.item.title"></lazy-image>
                <div class="">{{ data.item.title }}</div>
              </div>
            
            </template>
              <template #cell(status)="data">
                <div  v-if="data.item.status=='ACTIVE'" class="quizell-badge quizell-badge-active">
                  Active
                </div>
                <div   v-else-if="data.item.status=='DRAFT'" class="quizell-badge" style="color:var(--grey-button);background:var(--border-color)">
                  Draft
                </div>
                <div  v-else   class="quizell-badge quizell-badge-inactive">
                  Archived
                </div>
                   
                 
             
            </template>
          </b-table>
        </div>
      </div>
      <template v-else>

<div class="d-flex justify-content-center align-items-center"  >

<EmptyTable >
<template #title>
No product found
</template>
<template #description>
Time to fill your shelves or refine your search. Remember, your results will appear right here.
</template>
</EmptyTable>
</div>

</template>
      <div class="d-flex py-2 justify-content-between">
        <ShopifyPagination
          :hasNext="pageInfo.hasNextPage"
          @onNext="
            () => {
              queryObject.before = '';
             queryObject.productsFirst=50;
               queryObject.productsLast = null;
              queryObject.after = pageInfo.endCursor;
              getProductsFromShopify();
            }
          "
          :hasPrevious="pageInfo.hasPreviousPage"
          @onPrevious="
            () => {
              queryObject.after = '';
              queryObject.productsLast = 50;
               queryObject.productsFirst=null;
              queryObject.before = pageInfo.startCursor;
              getProductsFromShopify();
            }
          "></ShopifyPagination>
          <div class="">
            <div
            class="d-flex py-2 py-lg-0 w-100 justify-content-end  align-items-center"
            >
            <BorderButton
              :custom="true"
              @click="syncSelected('')"
              :disabled="
                isLoadingSync ||
                isLoadingShopifyProducts ||
                isLoadingSelectedShopifyProducts
              "
              :isLoading="isLoadingSelectedShopifyProducts"
              class="secondary-color-button mr-3"
              v-if="shopifySelectedProducts.length"
              >Sync Selected</BorderButton
            >
            <Button
              :disabled="
                isLoadingSync ||
                isLoadingShopifyProducts ||
                isLoadingSelectedShopifyProducts
              "
              v-else
              :isLoading="isLoadingSync||isLoadingSelectedShopifyProducts"
              @click="syncSelected('all')">
              <span >Sync All Products</span>
            </Button>
          </div>
          </div>
      </div>
    </ModalVue>
  
      
  </div>
 
</template>
<script>
import BorderButton from "../../Layout/BorderButton.vue";
import { mapGetters  } from "vuex";
import { debounce } from "lodash";
import FilterSelect from "../../Layout/FilterSelect.vue";
import ShopifyPagination from "../../Layout/ShopifyPagination.vue";
import FilterBox from "../../Layout/FilterBox.vue";
import EmptyTable from "../../Layout/EmptyTable.vue";
import ModalVue from "../../Layout/Modal.vue";
import ProgressbarVue from "../../Layout/Progressbar.vue";
import axios from "axios";
import Button from '../../Layout/Button.vue';
export default {
  components: {
    FilterBox,
    FilterSelect,
    ShopifyPagination,
    ModalVue,
    Button,
    BorderButton,
    ProgressbarVue,
   EmptyTable
   
  },
 
  data() {
    return {
      withVariant:false,
      syncType:'selected',
      isLoadAddProduct:false,
    
      selectedQuizellProducts: [],
      searchProductText: "",
      currentPage: 1,
      perPage: 10,
      totalRows: 10,
      pageInfo: {
        hasNextPage: false,
        hasPreviousPage: false,
        endCursor: "",
        startCursor: "",
      },
      isLoadingShopifyProducts: false,
      isLoadingShopifyProductFirstTime: false,
      shopifySelectAllCheck: false,
      shopifySelectedProducts: [],
    
      shopifyProducts: [],
      shopifyProductsFields: [
        {
          key: "checkbox",
          label: "",
          tdClass: "align-middle data-Table cursor-pointer",
          thClass: "bg-table-heading",
        },
        {
          key: "image",
          label: "Product",
          tdClass: "align-middle data-Table cursor-pointer",
          thClass: "bg-table-heading",
        },
        
        {
          key: "Status",
          label: "status",
          tdClass: "align-middle data-Table cursor-pointer",
          thClass: "bg-table-heading",
        },


        
      ],
      items: [],
      isLoadingFirstTime: true,
      isLoadingSelectedShopifyProducts: false,
      isLoadingDeleteProduct: false,
      isLoadingSync: false,
      isLoading: false,
      productProducts:[],
      queryObject: {
               
                before: null,
                after: null,
               
            
            },

filterObject:{
  tags:[],
  vendor:[],
  collections:[]
},
filter: { tag: '', vendor: '', collection: '', status: [], input: '' },
    };
  },
  watch: {
  
    filter:{
      handler(){
        this.handleSearchDebounced();
      },
      deep:true
    },
    allProducts:{
      handler({selected,all}){
     
       this.shopifySelectAllCheck=all.every(product=>selected.includes(product))
  
      },
      deep:true
    },
   
  },
  computed: {
    payload(){
      
      const { tag, vendor, collection, status, input } = this.filter
       const data = {
     tag:tag,
     vendor:vendor,
     collection_id:collection,
     status:status,
     search:input,
        ...this.queryObject,
      };
      return data
    },
    isHideSelectAllButton(){
     return this.filter.status.length>0||this.filter.vendor||this.filter.collection||this.filter.tag
    },
    ...mapGetters(["getNavbarData"]),
    allProducts(){
      return {selected:this.shopifySelectedProducts,all:this.shopifyProducts}
    },
  
    shopifyAllProducts() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.shopifyProducts
    },
  },
  methods: {
   
   
     clearAll(){
      this.$refs.tags.clear();
      this.$refs.collections.clear();
       this.$refs.vendors.clear();
      this.$refs.status.clear();
    },
    RowClick(e){
      let index = this.shopifySelectedProducts.findIndex(product=>product==e)
      if(index>-1)
      {
        this.shopifySelectedProducts.splice(index,1)
        return
      }
      this.shopifySelectedProducts.push(e)
    },
   
    open() {
   
        this.queryObject= {
               
                before: null,
                after: null,
               
            },
        this.isLoadingShopifyProductFirstTime = true;
      this.$refs.Modal.open();
      this.shopifySelectAllCheck=false;
      this.getProductsFromShopify();
      this.shopifySelectedProducts=[];
    },
    async syncSelected(type) {
      this.isLoadingSelectedShopifyProducts = true;
      let payload=null
      if(type=='all')
      {
        payload={filters:this.payload}
      }
      else
      {
        payload={selected_items :this.shopifySelectedProducts}
      }
      payload.withVariant=this.withVariant
      await axios
        .post("/shopify/import-products", payload)
        .then((response) => {
          this.$refs.Modal.close();
          this.$emit("updateProducts","/product/list");
          this.$toasted.show(response.data.data, {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
          this.$emit("updateProducts","/product/list");
        })
        .catch((e) => {
          this.$toasted.show("Error occurred while syncing products", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
          throw e
        });
      this.isLoadingSelectedShopifyProducts = false;
    },
    searchProductQueryHandle(e) {
      this.queryObject.after = "";
      this.queryObject.before = "";
      this.filter.input = e;
      
    },
    shopifyProductSelectAllToggle() {
      this.shopifySelectedProducts = [];
      if (this.shopifySelectAllCheck) {
        this.shopifySelectedProducts = this.shopifyAllProducts;
      }
    },
  
    async loadShopifyCollectionsTags(){
        await axios.get('/loadShopifySearchData').then(resp=>{
          const response=resp.data.data;
          this.filterObject.collections=response.collections.edges.map(collect=>(
            {
                 id: parseInt(collect.node.id.replace('gid://shopify/Collection/',"")),
                title: collect.node.title
            }
          ))
           this.filterObject.tags=response.productTags.edges.map(tag=>(
            {
                title:tag.node
            }
          ))
           this.filterObject.vendor=response.productVendors.edges.map(vendor=>(
            {
                title:vendor.node
            }
          ))
        }).catch(e=>e)
      
    },
   
    async getProductsFromShopify() {
      this.isLoadingShopifyProducts=true;
      await axios
        .post("/shopify/load-products", this.payload)
        .then((response) => {
         
          if (response.data.status) {
  
           const edges = response.data.data.products
            let {hasNext,hasPrevious,nextURL,previousURL} =  response.data.data.pageInfo
            this.pageInfo.hasNextPage =hasNext;
            this.pageInfo.hasPreviousPage =hasPrevious;
             this.pageInfo.startCursor =previousURL;
             this.pageInfo.endCursor =nextURL;
            this.shopifyProducts=edges
          
          } else {
            this.$toasted.show("Error occurred getting products. ", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
          }
          
        })
        .catch((e) => {
          console.log(e);
        });
      this.isLoadingShopifyProducts = false;
      this.isLoadingShopifyProductFirstTime = false;
    },
 

    handleSearchDebounced: debounce(function () {
      this.getProductsFromShopify();
    }, 500), // 500ms debounce time, adjust as needed
   
  },
  mounted() {
   this.loadShopifyCollectionsTags()
  },
};
</script>
<style scoped>
.secondary-color-button{
  color:var(--secondary-color); border:1px solid var(--secondary-color); background:var(--white)
}
.secondary-color-button:hover{
  color:var(--white); border:1px solid var(--secondary-color); background:var(--secondary-color)
}
.shopify-btn:active {
  background-color: #002e25;
  color: #ffffff;
  border-color: #002e25;
}
.shopify-btn {
  appearance: none;
  cursor: pointer;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #008060;
  color: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;

  font-size: 14px;

  transition: 150ms ease;
  transition-property: background-color, border-color;
  display: inline-block;

  border-radius: 6px;
}
.checkbox >>> .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: var(--secondary-color);
  background-color: var(--secondary-color) !important;
}
.checkbox >>> .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem var(--secondary-color);
}
.leadTable >>> thead {
  border-radius: 6px;
  color: black;
  background: rgba(179, 175, 182, 0.07);
  border: none;
}
.leadTable >>> thead tr {
  border-radius: 10px;
}
.leadTable >>> thead th {
  border: none;
  vertical-align: middle !important;
}
.loader {
  min-height: 40px;
}
.product-image {
  height: 40px;
  width: 40px;
  /* border: 4px solid rgb(255, 255, 255); */
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
  background-size: cover;
  /* padding: 0.25rem; */
  /* background-color: #fff; */
}
.customPagination >>> li > button {
  color: #4d1b7e;
}

.customPagination >>> li.active > button {
  color: white;
  background-color: #4d1b7e !important;
  border-color: #4d1b7e;
}
.customPagination >>> li > button:hover {
  color: white;
  background-color: #6b4690 !important;
  border-color: #4d1b7e;
}
#product-detail >>> button {
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
}
#product-detail >>> button::after {
  display: none;
}
#product-detail >>> ul {
  background: #ffffff;
  box-shadow: 0px 10px 20px -3px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}
#product-detail >>> li.topbarLink {
  color: #344054;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px;
}
#product-detail >>> li a:active {
  color: #fff;
  text-decoration: none;
  background-color: #4d1b7e;
}
.leadTable>>>.table.b-table > thead > tr > .table-b-table-default{
background: #f4f3f4;
}
</style>